import {
  Callout,
  CalloutDescription,
  CalloutHeading,
} from '@optechai/design-system'

import type { ERROR_CODE } from '../helpers/errors'

export interface ErrorBoundaryProps {
  children: React.ReactNode
  error: ERROR_CODE | null
  title: string
}

export const ErrorBoundary = ({
  title = 'Something went wrong',
  error,
  children,
}: ErrorBoundaryProps) => {
  return (
    <div className="flex h-full flex-col items-center justify-center p-xxl">
      <Callout variant="error">
        <CalloutHeading>{title}</CalloutHeading>
        <CalloutDescription>
          {children}
          {error && <span className="hidden">{error}</span>}
        </CalloutDescription>
      </Callout>
    </div>
  )
}
